export const myUpdateCoach = /* GraphQL */ `
mutation UpdateCoach(
  $input: UpdateCoachInput!
  $condition: ModelCoachConditionInput
) {
  updateCoach(input: $input, condition: $condition) {
    id
    name
    organization
    bio
  }
}
`;

export const myCreateFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
  ) {
    createFeedback(input: $input) {
      id
  }
}
`;