import React, { useState, useEffect } from 'react'
import './Setting.css'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify';
import { myGetCoach } from '../../../Graphql/Queries'
import { myUpdateCoach } from '../../../Graphql/Mutations'
import { useTranslation } from 'react-i18next';
import Navigation from '../../Components/Navigation/Navigation'

function Setting(props) {
    const { t, i18n } = useTranslation();

    const authInfo = props.location.state.authInfo;
    const [userData, updateData] = useState([])
    const [formState, setFormState] = useState(userData)
    const [userEdit, toggleEdit] = useState(false)

    function setInput(key, value) {
        setFormState({ ...formState, [key]: value })
    }

    async function getCoachInfo() {
        try {
            const results = await API.graphql(graphqlOperation(myGetCoach, {id: authInfo}))
            const data = results.data.getCoach
            updateData(data)
            setFormState(data)
        } catch (err) {
            console.log('error fetching coach', err)
        }
    } 
    useEffect (() => {
        getCoachInfo();
    }, [])

    async function updateCoachInfo(e) {
        try {
            e.preventDefault();
            const updatedInfo = { ...formState }
            console.log(updatedInfo)
            const results = await API.graphql(graphqlOperation(myUpdateCoach, {input: updatedInfo}))
            const data = results.data.updateCoach
            updateData(data)
            toggleEdit(false)

        } catch (err) {
            console.log('error updating coach', err)
        }
    }

    return (
        <div>
            <Navigation />
            
            <h1 className='homeTitle'>{t("setting.title")}</h1>  

            {userEdit ? (
                <form onSubmit={updateCoachInfo}>
                    <input
                        className='detailInfo editTextSize'
                        onChange={val => setInput('name' , val.target.value)}
                        value={formState.name}
                        placeholder={t('setting.input.name')}
                    />
                    <input 
                        className='detailInfo editTextSize'
                        onChange={val => setInput('bio', val.target.value)}
                        value={formState.bio}
                        placeholder={t('setting.input.bio')}
                    />
                    <input 
                        className='detailInfo editTextSize'
                        onChange={val => setInput('organization', val.target.value)}
                        value={formState.organization}
                        placeholder={t('setting.input.organization')}
                    />
                    <button 
                        type='submit'
                        className='editButton'
                    > {t("setting.function.submit")}
                    </button>
                    <button 
                        className='editButton'
                        onClick={() => toggleEdit(false)}
                    > {t("setting.function.back")}
                    </button>
                </form>
            ) : (
                <div>
                    <div className='detailInfo'>
                        <h3>{userData.name}</h3>
                    </div>
                    <div className='detailInfo'>
                        <h3>{userData.bio}</h3>
                    </div>
                    <div className='detailInfo'>
                        <h3>{userData.organization}</h3>
                    </div>
                    <button 
                        className='editButton'
                        onClick={() => toggleEdit(true)}
                    > {t("setting.function.edit")}
                    </button> 
                </div>
            )} 
        </div>
    )
}

export default Setting
