import React, { useState } from 'react'
import './Navigation.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Navigation() {
    const { t, i18n } = useTranslation();
    const initialLng = i18n.language;
    const [toggleLng, updateLng] = useState(initialLng);
    
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        updateLng(lng)
    }; 
      
    return (
        <div>
            <Link to='/'>
                <button className='navButton'>
                    {t("navigation.home")}
                </button>
            </Link>
            { toggleLng === 'en' ? (
                <button className='navButton' type="button" onClick={() => changeLanguage('ch')}>
                    {t('navigation.lng.chinese')}
                </button>
            ) : (
                <button className='navButton' type="button" onClick={() => changeLanguage('en')}>
                    {t('navigation.lng.english')}
                </button>
            )}
        </div>
    )
}

export default Navigation;