import React, { useState, useEffect } from 'react'
import './DayListData.css'
import {Link} from 'react-router-dom'
import  { API, graphqlOperation } from 'aws-amplify';
import { myListDatas } from '../../../Graphql/Queries'
import { useTranslation } from 'react-i18next';

function DayListData (props) {
    const { t, i18n } = useTranslation();

    const {userID, clientName, date, userWeight, userHeight, authInfo} = props;
    const [userData, updateData] = useState([])

    async function realData() {
        try {
            const results = await API.graphql(graphqlOperation(myListDatas, {filter: {userId: {eq: userID}}}))
            const data = results.data.listDatas.items
            updateData((data).sort(sleepOrder))                
        } catch(err) {
            console.log('error fetching data', err)
        }
    }

    function sleepOrder(a,b) {
        if (a.end < b.end)
        {
            return -1
        }
        if (a.end > b.end){
            return 1
        }
        return 0
    }

    useEffect( () => {
        realData()
    }, [])

    var user, startTime, endTime, length, bar, currentData = false;

    for (let i = 0; i < userData.length; i++) {
        let formattedDate = new Date(userData[i].end)
        var userDate = formattedDate.toString().substring(4,15)

        if(userDate === date) {
            user = userID;
            currentData = true;
            startTime = new Date(userData[i].start)
            endTime = new Date(formattedDate)
            length = (endTime-startTime)/(3600*1000)
            if (length > 10) {
                bar = (length/24) * (100) + 'vw';
            } else {
                bar = (length/12) * (100) + 'vw';
            }
            break;
        }
    }
            
    return (
        <div>
            {currentData === true ? (
                <div className='dayListDataContainer'>
                    <div>
                        <Link style={{textDecoration: 'none'}} to={{pathname: './ClientScreen',
                            state: {userID: userID,
                                clientName: clientName, 
                                userWeight: userWeight, 
                                userHeight: userHeight,                                     
                                authInfo: authInfo}
                        }}>
                            <h2 className='dayListName'>{clientName}</h2>
                        </Link>
                    </div>
                    <div>
                        <button className='dayListDataBtn' style={{width: bar}}>
                            <h5>{length + " " + t("dayList.display.hour")}</h5>
                        </button>
                    </div>
                </div>
            ): (
                <div>
                    {/*<p className='noDataMessage'>no data to show</p>*/}
                </div> 
            )}
        </div>
    )
}

export default DayListData