import React, { useState, useEffect } from 'react'
import './Feedback.css'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify';
import { myCreateFeedback } from '../../../Graphql/Mutations'
import { myListFeedback } from '../../../Graphql/Queries'
import { useTranslation } from 'react-i18next';
import Navigation from '../../Components/Navigation/Navigation';

function Feedback(props) {
    const { t, i18n } = useTranslation();

    const {authInfo, dataID, userID, clientName} = props.location.state;
    const initialState = {title: '', priority: 'General', message: ''}
    const [formState, setFormState] = useState(initialState)
    //const [userEdit, toggleEdit] = useState(true)
    const [feedData, updateFeed] = useState([])

    async function realFeedback() {
        try {
            const results = await API.graphql(graphqlOperation(myListFeedback,
                {filter: {dataId: {eq: dataID }}}))
            updateFeed((results.data.listFeedbacks.items)[0]) 
        } catch(err) {
            console.log('error fetching feedback', err)

            return []
        }
    }

    useEffect (() => {
        realFeedback()
    }, [])

    function setInput(key, value) {
        setFormState({ ...formState, [key]: value })
    }

    async function createNewFeedback(e) {  
        try {
            e.preventDefault()
            const updatedInfo = { ...formState }
            
            const results = await API.graphql(graphqlOperation(myCreateFeedback, 
                {input: {userID: userID,
                Title: updatedInfo.title,
                dataId: dataID,
                priority: updatedInfo.priority,
                stamp: new Date(),
                message: updatedInfo.message,
                author: authInfo,
                }}))
            
            const feedId = results.data.createFeedback.id
            console.log('created feedback', feedId)

            } catch(err) {
                console.log('error creating feed', err)
            }
    }

    return (
        <div>
            <Navigation />
            
            {(feedData === undefined  || feedData.length === 0) ? (
                <div>
                    <h1>{t("feedback.title")} {clientName}</h1>
                    <form onSubmit={createNewFeedback}>
                        <input
                            type='text'
                            className='detailInfo feedbackInput'
                            placeholder={t("feedback.input.title")}
                            onChange={val => setInput('title' , val.target.value)}
                            value={formState.title}
                            required
                        />
                        <select 
                            className='detailInfo feedbackSelect'
                            onChange={val => setInput('priority' , val.target.value)}
                            value={formState.priority}
                        >
                            <option>{t("feedback.input.general")}</option>
                            <option>{t("feedback.input.concerning")}</option>
                            <option>{t("feedback.input.emergency")}</option>
                        </select>
                        <textarea 
                            className='detailInfo feedbackInput'
                            onChange={val => setInput('message' , val.target.value)}
                            value={formState.message}
                            placeholder={t("feedback.input.message")}
                            required
                        />
                        <button 
                            type='submit'
                            className='submitButton'
                        > 
                            {t("feedback.function.submit")}
                        </button>
                    </form>
                </div>
            ): (
                <div>
                    <h1>{t("feedback.viewFeedback")}</h1>
                        <div>
                            <div className='detailInfo'>
                                <h3>{feedData.stamp.substring(0,10)}</h3>
                            </div>
                            <div className='detailInfo'>
                                <h3>{feedData.Title}</h3>
                            </div>
                            <div className='detailInfo'>
                                <h3>{feedData.priority}</h3>
                            </div>
                            <div className='detailInfo feedbackMess'>
                                <h4 style={{marginLeft: 'auto'}}>{feedData.message}</h4>
                            </div>
                        </div>
                    
                {/*
                <div>
                    <h1>List</h1>
                    {(feedData !== undefined) ? (
                        <div>
                            <div className='detailInfo'>
                                <h3>{feedData.stamp.substring(0,10)}</h3>
                            </div>
                            <div className='detailInfo'>
                                <h3>{feedData.Title}</h3>
                            </div>
                            <div className='detailInfo'>
                                <h3>{feedData.priority}</h3>
                            </div>
                            <div className='detailInfo feedbackMess'>
                                <h4 style={{marginLeft: 'auto'}}>{feedData.message}</h4>
                            </div>
                        </div>
                    ): (
                        <div>
                            <p className='noDataMessage'>no feedback</p>
                        </div>
                    )}
                    <button className='submitButton' onClick={() => toggleEdit(true)}>
                        Write 
                    </button>
                </div> 
                    */}
                </div>
            )}      
        </div>
    )
}

export default Feedback
