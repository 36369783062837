import React, { useState } from 'react';
import './SnoreData.css'
import { useTranslation } from 'react-i18next';

function SnoreData(props) {
    const { t, i18n } = useTranslation();

    const userSleep = props.data;
    const [volData, toggleData] = useState(false);

    const toggle = (view) => {
        if (view) {
            toggleData(previousState => !previousState)
        }
    }

    function sleepOrder(a,b) {
        if (a.end > b.end)
        {
            return 1
        }
        if (a.end < b.end){
            return -1
        }
        return 0
    }

    return (
        <div>
            <div className='toggleView'>
                <button 
                    style={{backgroundColor: (volData ? '#0E90CD' : 'white'), 
                            color: (volData ? 'white' : '#0E90CD'), 
                            borderTopLeftRadius: 20}} 
                    className='toggleButton' 
                    onClick={() => toggle(!volData)}>
                    {t("snoreScreen.snoringData.function.duration")}
                </button>
                <button  
                    style={{backgroundColor: (volData ? 'white' : '#0E90CD'), 
                        color: (volData ? '#0E90CD' : 'white'), 
                        borderBottomRightRadius: 20}}  
                    className='toggleButton' 
                    onClick={() => toggle(volData)}>
                    {t("snoreScreen.snoringData.function.volume")}
                </button>
            </div> 

            <hr className='divisionLine' />

            <div>
                {volData ?
                    <div className='snoreScrollMenu'>

                    {userSleep.items.sort(sleepOrder).map((data, index) => {
                                            
                        const start2 = new Date(data.start)
                        const end2 = new Date(data.end)
                        const length2 = parseInt((end2-start2) / (60*1000),10)
                        const bar2 = length2/50 * window.innerHeight;                        
                                        
                        return (
                            <div key ={index} className='snoreDataContainer'>
                                <h4 className='snoreUpperText'>{data.start.substring(11,16)}</h4> 
                                <div className='snoreMiddleBar'>                            
                                    <button 
                                        className='snoreDataButton' 
                                        style={{backgroundColor: data.tag === 'Yes' ? "#ff9900" : "#0E90CD", height:bar2}}
                                    />
                                </div>
                                <h4 className='snoreLowerText'>{data.end.substring(11,16)}</h4>                      
                            </div> 
                        )
                    })}   
                </div>
                :
                    <div className='snoreScrollMenu'>
                        {userSleep.items.sort(sleepOrder).map((data, index) => {
                                    
                            const start2 = new Date(data.start)
                            const end2 = new Date(data.end)
                            const length2 = parseInt((end2-start2) / (60*1000),10)
                            const bar2 = length2/50 * window.innerHeight; 

                            return (
                                <div key ={index} className='snoreDataContainer'>
                                    <h4 className='snoreUpperText'>{data.volume  + t("snoreScreen.snoringData.display.volume")}</h4> 
                                    <div className='snoreMiddleBar'>
                                        <button 
                                            className='snoreDataButton' 
                                            style={{ backgroundColor: data.tag === 'Yes' ? "#ff9900" : "#0E90CD", height:bar2}}
                                        />
                                    </div>  
                                    <h4 className='snoreLowerText'>{length2 + t("snoreScreen.snoringData.display.duration")}</h4>                      
                                </div> 
                            )
                        })}  
                    </div>
                }
            </div>

            <hr className='divisionLine' />
        </div>
    )   
}
    
export default SnoreData;