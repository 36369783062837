import React, { useState, useEffect } from 'react'
import './Home.css'
import { Link } from 'react-router-dom'
import  { Auth, API, graphqlOperation } from 'aws-amplify';
import { myGetCoachUsers } from '../../../Graphql/Queries'
import { useTranslation } from 'react-i18next';

function Home() {
    const { t, i18n } = useTranslation();
    
    const authInfo = Auth.user.attributes.email;
    const [userData, updateData] = useState([])
    const userInfo = []

    async function getCoachInfo() {
        try {
            const results = await API.graphql(graphqlOperation(myGetCoachUsers, {id: authInfo}))
            const data = results.data.getCoach.users.items
            updateData(data)
        } catch (err) {
            console.log('error fetching coach users', err)
        }
    }

    useEffect(() => {
        getCoachInfo();
    }, [])

    for (let i = 0; i < userData.length; i++) {
        userInfo.push([userData[i].id, userData[i].name, userData[i].height, userData[i].weight])
    }
    
    async function checkUser() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log("user: ", user);

        } catch (error) {
            console.log('error checking: ', error)
        }
    }
    async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };
    return (
        <div className='alignButton'>     
            <h1 className='homeTitle'>{t('homepage.title')}</h1>  
            <Link 
                to={{pathname:'/ClientList',
                state: {authInfo: authInfo}}}
            >
                <button className='homeButton'>
                    {t('homepage.link.clientList')}
                </button>
            </Link>
            <Link 
                to={{pathname:'/Calendar',
                state: {authInfo: authInfo, userInfo: userInfo}}}
            >
                <button className='homeButton'>
                    {t('homepage.link.calendar')}
                </button>
            </Link>
            <Link 
                to={{pathname:'/Setting',
                state:{authInfo: authInfo}}}                  
            >
                <button className='homeButton'>
                    {t('homepage.link.setting')}
                </button>
            </Link>
            <Link to='/'>
                <button onClick={signOut} className='homeButton'>
                    {t('homepage.link.signOut')}
                </button>
            </Link>
                
            {/*<button onClick={checkUser} className='homeButton'>
                checkUser
            </button>
            */}
        </div>
    )
}

export default Home
