import React, { Suspense } from 'react';
import './index.css'
import Home from './ExpertInterface/Pages/HomePage/Home'
import Calendar from './ExpertInterface/Pages/Calendar/Calendar'
import DayList from './ExpertInterface/Pages/DayList/DayList'
import ClientList from './ExpertInterface/Pages/ClientList/ClientList'
import ClientScreen from './ExpertInterface/Pages/ClientScreen/ClientScreen'
import Setting from './ExpertInterface/Pages/Setting/Setting'
import SleepData from './ExpertInterface/Components/SleepData/SleepData'
import SnoreScreen from './ExpertInterface/Pages/SnoreScreen/SnoreScreen'
import Login from './LoginForm/Login'
import Feedback from './ExpertInterface/Pages/Feedback/Feedback'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import config from './aws/aws-exports'
import Amplify, { Auth } from 'aws-amplify';
Amplify.configure(config) 
Auth.configure(config) 

function App() {
  const Loader = () => (
      <div>loading...</div>
  );
  
  return (

    <div>
        <Suspense fallback={<Loader />}>
          <Router>
            <Switch>
              <Route path='/' exact component={Login} />
              <Route path='/Home' exact component={Home} />
              <Route path='/Calendar' component={Calendar} />
              <Route path='/DayList' component={DayList} />
              <Route path='/ClientList' component={ClientList} />
              <Route path='/ClientScreen' component={ClientScreen} />
              <Route path='/Setting' component={Setting} />
              <Route path='/SleepData' component={SleepData} />
              <Route path='/SnoreScreen' component={SnoreScreen} />
              <Route path='/Feedback' component={Feedback} />
            </Switch>
          </Router> 
        </Suspense>

    </div>
  );
}

export default App
