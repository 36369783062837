import React from 'react';
import './SleepData.css'
import { Link } from 'react-router-dom'

function SleepData(props) {

    const {userData, authInfo, userID, clientName} = props

    return (      
        <div>
            <hr className='divisionLine' />
            <div className='scrollMenu'>
                {userData.map((data, index) => {

                    const startTime = new Date(data.start)
                    const endTime = new Date(data.end)
                    const length = (endTime-startTime)/(3600*1000)
                    const bar = (length/24) * window.innerHeight;

                    const snoreProcess = (snore, selection) => {
                        const startTime2 = new Date(snore.start)
                        const endTime2 = new Date(snore.end)
                        const length2 = (endTime2 - startTime2)/(3600*1000)
                        const bar2 = parseInt(length2/length * (100),10) + '%' 
                        const pos = ((endTime-endTime2)/(3600*1000))/length
                        const mark = parseInt((pos * 100),10) + '%'
                        if (selection === true) {
                            return bar2
                        } else {
                            return mark
                        }
                    }

                    return (
                        <div key ={index} className='sleepDataContainer'>
                            <h4 className='upperText'>{data.start.substring(11,16)}</h4> 
                                <div className='middleBar'>
                                    <Link 
                                        to={{pathname:'/SnoreScreen',
                                        state: {snoreData: data.snoring,
                                                authInfo: authInfo,
                                                start: data.start.substring(11,16), 
                                                end: data.end.substring(11,16),
                                                length: length,
                                                dataID: data.id,
                                                userID: userID,
                                                clientName: clientName }}}
                                    >
                                        <button className='sleepDataButton'
                                            style={{backgroundColor: data.tag === 'Yes' ? "#ff9900" : "#0E90CD", height:bar}}
                                        > 
                                            {data.snoring.items.map((realData, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className='snoringData' style={{height: snoreProcess(realData, true), bottom: snoreProcess(realData, false)}} > </div>
                                                    </div>
                                                )
                                            })}
                                        </button>  
                                    </Link> 
                                </div>   
                                
                                <h4 className='lowerText'>{data.end.substring(11,16)}</h4>  
                                <h4 className='lowerDateText'><b>{data.end.substring(5,10)}</b></h4>                               
                        </div>
                    ) 
                })}    
            </div>
            <hr className='divisionLine' />
        </div>
    )
}

export default SleepData
