import React, {useState, useEffect} from 'react';
import './Login.css'
import Home from '../ExpertInterface/Pages//HomePage/Home'
import config from '../aws/aws-exports'
import Amplify, {Auth} from 'aws-amplify';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next';

Amplify.configure(config) 
Auth.configure(config) 

const initialFormState = {
username: '', password: '', confirmPassword: '', email: '', name:'', gender:"male", birthdate: '', authCode: '', formType: 'signIn',
}

function Login() {
    const { t, i18n } = useTranslation();
    const initialLng = i18n.language;
    const [toggleLng, updateLng] = useState(initialLng);
    
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        updateLng(lng)
    }; 

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVis = () => {
        setPasswordShown(passwordShown ? false : true);
    }
    
    const [formState, updateFormState] = useState(initialFormState);
    const [user, updateUser] = useState(null);
    const { formType } = formState;

    useEffect(() => {
        checkUser();
    }, []);

    async function checkUser() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log("user: ", user);
            updateUser(user);
            updateFormState(() => ({ ...formState, formType: 'signedIn' }));

        } catch (error) {
            updateUser(null)
        }
    }
    
    async function signUp() {
        try {
            const { username, password, gender, birthdate, name} = formState;
            await Auth.signUp({ username, password, attributes: {'email': username, 'name' : name,
             'birthdate': birthdate, 'gender': gender } });
            updateFormState(() => ({ ...formState, formType: 'confirmSignUp' }));
        } catch (error) {
            alert(error.message)
            console.log("error signing up: ", error)
        } 
    }

    async function confirmSignUp() {
        try {
            const { username, authCode } = formState;
            await Auth.confirmSignUp(username, authCode);
            updateFormState(() => ({ ...formState, formType: 'signIn' }));
        } catch (error) {
            console.log("error confirming: ", error)
        }
    }

    async function signIn() {
        const { username, password } = formState;
        if (username === '' || password === '') {
            alert(t("login.display.error.missing"))
        } else {
            try {
                await Auth.signIn({username, password});
                updateFormState(() => ({ ...formState, formType: 'signedIn' }));
            } catch  (error) {
                    console.log("error signing in: ", error)
                    alert(error.message)
            }
        }       
    }
    /*async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }; */
    function onChange(e) {
        e.persist();
        updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
    }  
       
    return (
        <Formik
            initialValues={{ name: "", username: "", password: "", confirmPassword: "", gender: "", birthdate: "" }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 500);
            }} 
            
            validate={values => {
                let errors = {};
                const genderType = values.gender.toLowerCase().trim();
                if ((genderType !== 'male') && (genderType !== 'female') && (genderType !== 'others')) {
                  errors.gender = t("login.display.error.gender");
                }
                return errors;
            }}
            
            validationSchema={Yup.object().shape({
               
                name: Yup.string()
                .required(t("login.display.error.name")),
                username: Yup.string()
                .email(t("login.display.error.notEmail"))
                .required(t("login.display.error.email")),
                password: Yup.string()
                .required(t("login.display.error.password"))
                .min(8, t("login.display.error.minPassword")),
                confirmPassword: Yup.string()
                .oneOf([Yup.ref("password")], t("login.display.error.confirmPassword"))
                .required(t("login.display.error.confirmPassword")),
                birthdate: Yup.date()
                .required(t("login.display.error.birthdate"))
                .max(new Date(), t("login.display.error.maxBirthdate")),
                /*gender: Yup.string().trim()
                .oneOf(['male','female','others','undefined'], 'Please choose one of them')
                */
                
            })}
        >

        {props => {
            const {
                values,
                touched,
                errors,
                isValid,
                isSubmitting,
                dirty,
                handleBlur,
                handleChange,
                handleSubmit
                } = props;

                return ( 
                    <div>
                        { toggleLng === 'en' ? (
                            <button className='navButton' type="button" onClick={() => changeLanguage('ch')}>
                                {t('navigation.lng.chinese')}
                            </button>
                        ) : (
                            <button className='navButton' type="button" onClick={() => changeLanguage('en')}>
                                {t('navigation.lng.english')}
                            </button>
                        )}
                        {/*<button onClick={getUserInfo}>testing</button>*/}
                        {formType === 'signUp' && (
                            <div className='container'>

                                <h1 style={{marginTop: 50, marginBottom: 50}}>{t("login.title.signUp")}</h1>
                                <form className='formStyle' onSubmit={handleSubmit}>
                                    <input 
                                        id='inputStyle' 
                                        name='name' 
                                        value={values.name}
                                        onInput={onChange} 
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        placeholder={t("login.input.name")}
                                        className={errors.name && touched.name && "error"}
                                    /> {errors.name && touched.name && (
                                        <div className="inputFeedback">{errors.name}</div>
                                    )}
                                    <input 
                                        name="password"
                                        type={passwordShown ? 'text' : 'password'}
                                        placeholder={t("login.input.password")}
                                        value={values.password}  
                                        onInput={onChange}                                      
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id='inputStyle'
                                        className={errors.password && touched.password && "error"}
                                    /> 
                                    <input 
                                        name="confirmPassword"
                                        type={passwordShown ? 'text' : 'password'}
                                        placeholder={t("login.input.confirmPassword")}
                                        value={values.confirmPassword}  
                                        onInput={onChange}                                      
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id='inputStyle'
                                        className={errors.confirmPassword && touched.confirmPassword && "error"}
                                    /> 
                                   <p className='passwordVis' onClick={togglePasswordVis}>{passwordShown ? t("login.function.hidePassword") : t("login.function.showPassword")}</p>
                                    {errors.password && touched.password && (
                                        <div className="inputFeedback">{errors.password}</div>
                                    )}
                                    {errors.confirmPassword && touched.confirmPassword && (
                                        <div className="inputFeedback">{errors.confirmPassword}</div>
                                    )}
                                    <input 
                                        id='inputStyle' 
                                        name='username' 
                                        value={values.username}
                                        onInput={onChange} 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={t("login.input.email")}
                                        className={errors.username && touched.username && "error"} 
                                    /> {errors.username && touched.username && (
                                        <div className="inputFeedback">{errors.username}</div>
                                    )}
                                    <select 
                                        id='inputStyle'
                                        name='gender'
                                        value={values.gender}
                                        onInput={handleChange}
                                        onChange={onChange}
                                        onBlur={handleBlur}
                                    >
                                        <option>{t("login.input.male")}</option>
                                        <option>{t("login.input.female")}</option>
                                        <option>{t("login.input.others")}</option>
                                    </select>

                                    {/*<input 
                                        id='inputStyle'
                                        name='gender' 
                                        value={values.gender}
                                        onInput={onChange} 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={t("login.input.gender")}
                                        className={errors.gender && touched.gender && "error"} 
                                    />  {errors.gender && touched.gender && (
                                        <div className="inputFeedback">{errors.gender}</div>
                                    )}*/}
                                    <input 
                                        type='date'
                                        id='inputStyle'
                                        name='birthdate' 
                                        placeholder='mm-dd-yyyy:'
                                        value={values.birthdate}
                                        onInput={onChange} 
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.birthdate && touched.birthdate && "error"} 
                                    />   
 
                                    {errors.birthdate && touched.birthdate && (
                                        <div className="inputFeedback">{errors.birthdate}</div>
                                    )}    
                                    <button value='submit' className='buttonStyle' onClick={signUp} disabled={ ((!(isValid && dirty)) || isSubmitting) }>{t("login.function.signUp")}</button>
                                </form>
                                
                        
                             
                                <div className='reminderStyle'>{t("login.display.signIn")}
                                    <h3 style={{fontSize: 15}} className='switchStyle' onClick={() => updateFormState(() => ({
                                        ...formState, formType: 'signIn'
                                    }))}>{t("login.function.switch.signUp")}</h3> 
                                </div>
                                
                            </div>
                        )}
                    

                        {formType === 'confirmSignUp' && (
                            <div className='container'>
                                <h1 style={{marginTop: 50, marginBottom: 50}}>{t("login.title.confirmedSignUp")}</h1>
                                <form className='formStyle'>
                                    <input 
                                        id='inputStyle'
                                        name='username' 
                                        onChange={onChange} 
                                        placeholder={t("login.input.name")} />
                                    <input 
                                        id='inputStyle'
                                        name='authCode' 
                                        onChange={onChange} 
                                        placeholder={t("login.input.confirmationCode")} />
                                    <button value='submit' className='buttonStyle' onClick={confirmSignUp} disabled={ ((!(isValid && dirty)) || isSubmitting) }>{t("login.function.confirmedSignUp")}</button>
                                </form>                          


                            </div>
                        )}
                        
                        {formType === 'signIn' && (
                            <div className='container'>
                                <h1 style={{marginTop: 50, marginBottom: 50}}>{t("login.title.signIn")}</h1>
                                <form className='formStyle' onSubmit={handleSubmit}>
                                    <input 
                                        id='inputStyle' 
                                        name='username' 
                                        placeholder={t("login.input.email")}
                                        onChange={onChange}
                                        />  
                                    

                                    <input 
                                        name="password"
                                        type={passwordShown ? 'text' : 'password'}
                                        placeholder={t("login.input.password")}
                                        onChange={onChange}
                                        id='inputStyle'

                                    /> 
                                    <p className='passwordVis' onClick={togglePasswordVis}>{passwordShown ? t("login.function.hidePassword") : t("login.function.showPassword")}</p>
                                    
                                    <button value='submit' className='buttonStyle' onClick={signIn}>{t("login.function.signIn")}</button>
                                </form>
                                    <div className='reminderStyle'>{t("login.display.signUp")}
                                        <h3 style={{fontSize: 15}} className='switchStyle' onClick={ () => updateFormState(() => ({
                                           ...formState, formType: 'signUp',

                                        }))}>{t("login.function.switch.signIn")}</h3> 
                                    </div>
                            </div>
                        )}
                        {formType === 'signedIn' && (
                            <div className='align'>
                                <Home />
                                
                            </div>
                        )}
                    </div>
                )}                  
            }
        </Formik> 
    );
}

export default Login

/*!
formik v2.2.9
Docs & License: https://formik.org
(c) 2004 formik

yup v0.39.2
Docs & License: https://github.com/jquense/yup
(c) 2014 Jason Quense
*/