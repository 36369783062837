import React, { useState, useEffect } from 'react'
import './DayList.css'
import DayListData from '../../Components/DayListData/DayListData'
import { Link } from 'react-router-dom'
import  { API, graphqlOperation } from 'aws-amplify';
import { myGetCoachUsers } from '../../../Graphql/Queries'
import { useTranslation } from 'react-i18next';
import Navigation from '../../Components/Navigation/Navigation'

function DayList(props) {
    const { t, i18n } = useTranslation();

    const date = props.location.state.date;
    const authInfo = props.location.state.authInfo;
    const [userData, updateData] = useState([])

    const currentDate = date.toString().substring(4,15)

    async function getCoachInfo() {
        try {
            const results = await API.graphql(graphqlOperation(myGetCoachUsers, {id: authInfo}))
            const data = results.data.getCoach.users.items
            updateData(data)
        } catch (err) {
            console.log('error fetching coach users', err)
        }
    }
    useEffect(() => {
        getCoachInfo();
    }, [])

    return (
        
        <div>
            <Navigation />

            <h1>{currentDate}</h1>       

            {userData.map((data, index) => {
                return ( 
                    <div key={index}>
                        <DayListData 
                            userID={data.id} 
                            date={currentDate}
                            clientName={data.name}
                            userWeight={data.weight} 
                            userHeight={data.height} 
                            authInfo={authInfo}
                        />
                    </div>
                )
            })}   
        </div>
    )
}

export default DayList
