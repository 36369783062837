import React, { useEffect, useState } from 'react'
import './ClientList.css'
import { Link } from 'react-router-dom'
import  { API, graphqlOperation } from 'aws-amplify';
import { myGetCoachUsers } from '../../../Graphql/Queries'
import { useTranslation } from 'react-i18next';
import Navigation from '../../Components/Navigation/Navigation'

function ClientList(props) {
    const { t, i18n } = useTranslation();

    const authInfo = props.location.state.authInfo;
    const [userData, updateData] = useState([])

    async function getCoachInfo() {
        try {
            const results = await API.graphql(graphqlOperation(myGetCoachUsers, {id: authInfo}))
            const data = results.data.getCoach.users.items
            updateData(data)
        } catch (err) {
            console.log('error fetching coach users', err)
        }
    }

    useEffect(() => {
        getCoachInfo();
    }, [])

    return (              
        <div> 
            <Navigation />
            
            <h1>{t("clientList.title")}</h1>
            
            {userData.length !== 0 ? (
                <div>
                    {userData.map((data, key) => {
                        return (
                            <div key={key}>
                                <Link 
                                    style={{textDecoration: 'none'}} 
                                    to={{pathname:'/ClientScreen',
                                    state: {
                                        userID: data.id,
                                        clientName: data.name, 
                                        userWeight: data.weight, 
                                        userHeight: data.height, 
                                        authInfo: authInfo,}}}
                                >
                                    <h2 className='clientListName'>{data.name}</h2>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            ) : (
                <div>
                    <p className='noDataMessage'>{t("clientList.display.noData")}</p>
                </div>
                )
            }    
        </div>
    )
}

export default ClientList
