export const myListDatas = /* GraphQL */ `
query myListDatas(
  $filter: ModelDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      start
      end
      tag
      snoring {
          items {
            start 
            end
            volume
            tag
          }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const myGetUser = /* GraphQL */ `
  query myGetUser($id: String!) {
    getUser(id: $id) {
      id
      name
      sex
      height
      weight
    }
  }
`;

export const myGetCoach = /* GraphQL */ `
  query GetCoach($id: String!) {
    getCoach(id: $id) {
      id
      name
      organization
      bio
    }
  }
`;

export const myGetCoachUsers = /* GraphQL */ `
  query GetCoach($id: String!) {
    getCoach(id: $id) {
      users {
        items {
          id 
          name
          height
          weight
        }
      }
    }
  }
`;

export const myListFeedback = /* GraphQL */ `
  query myListFeedback(
    $filter: TableFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Title
        author
        dataId
        priority
        message
        stamp
      }
      nextToken
  }
}
`;