import React, { useEffect, useState } from 'react'
import './ClientScreen.css'
import SleepData from '../../Components/SleepData/SleepData'
import { myListDatas } from '../../../Graphql/Queries'
import { API, graphqlOperation } from 'aws-amplify';
import Navigation from '../../Components/Navigation/Navigation'
import { useTranslation } from 'react-i18next';

function ClientScreen(props) {
    const { t, i18n } = useTranslation();

    const {userID, clientName, userWeight, userHeight, authInfo} = props.location.state;
    const [userData, updateData] = useState([])
    console.log(userData)

    async function realData() {
        try {
            const results = await API.graphql(graphqlOperation(myListDatas, {filter: {userId: {eq: userID}}}))
            const data = results.data.listDatas.items
            updateData((data).sort(sleepOrder))
            
        } catch(err) {
            console.log('error fetching data', err)
        }
    }

    function sleepOrder(a,b) {
        if (a.end > b.end)
        {
            return -1
        }
        if (a.end < b.end){
            return 1
        }
        return 0
    }

    useEffect( () => {
       realData();
    }, [])
    
    return (
        <div>
            <Navigation />
            
            <h1>{clientName}</h1>
            
            {userData.length !== 0 ? (
                <div>
                    <SleepData 
                        userData={userData} 
                        authInfo={authInfo} 
                        userID={userID}
                        clientName={clientName}
                    />        
                    <div className='detailInfo'>
                        <h3>{userHeight + t("clientScreen.display.heightMeasure")}</h3>
                    </div>
                    <div className='detailInfo'>
                        <h3>{userWeight + t("clientScreen.display.weightMeasure")}</h3>
                    </div>
                </div>
            ) : (
                <div>
                    <p className='noDataMessage'>{t("clientScreen.display.noData")}</p>
                </div>
            )}  
        </div>
    )    
}
   
export default ClientScreen
