import React, { useState, useEffect } from 'react'
import './Calendar.css'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import { useHistory } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify';
import { myListDatas } from '../../../Graphql/Queries'
import { useTranslation } from 'react-i18next';
import Navigation from '../../Components/Navigation/Navigation'

function Calendar(props) {
    const { t, i18n } = useTranslation();
    const lang =  {
        code: t("calendar.lng"),
        buttonText: {
          today: t("calendar.function.today"),
          month: t("calendar.function.month"),
          week: t("calendar.function.week"),
          day: t("calendar.function.day"),
        },    
      }

    const authInfo = props.location.state.authInfo;
    const userInfo = props.location.state.userInfo;
    const history = useHistory();
    const [userData, updateData] = useState([])
    const userList = [];
    console.log(userInfo)
    async function realData() {
        try {
            for (let i = 0; i < userInfo.length; i++) {
                const results = await API.graphql(graphqlOperation(myListDatas, {filter: {userId: {eq: userInfo[i][0]}}}))
                const data = results.data.listDatas.items
                userList.push([userInfo[i][0],userInfo[i][1],userInfo[i][2], userInfo[i][3],(data)])
            }
            updateData(userList)         
        } catch(err) {
            console.log('error fetching data', err)
        }
    }
    console.log(userData)
    useEffect( () => {
        realData();  
    }, [])

    const createEvent = () => {
        const eventList = []
        if (userData.length !== 0) {
            for (let i = 0; i < userData.length; i++) {
                for (let d = 0; d < userData[i][4].length; d++) {
                    var formattedDate = userData[i][4][d].end.substring(0,10)
                    eventList.push({title: userData[i][1], date: formattedDate, userID: userData[i][0], height: userData[i][2], weight: userData[i][3] })
                }
            }
        }
        console.log(eventList)
        return eventList;
    }

    const handleNavLinkDayClick = (arg) => {
        let formattedDate = new Date(arg)
        const location = {
            pathname: '/DayList',
            state: {date: formattedDate, authInfo: authInfo},
        }
        return (
            history.push(location)
        )   
    }
    const handleEventClick = (info) => {
        console.log(info)
        const location = {
            pathname: '/ClientScreen',
            state: {
                authInfo: authInfo, 
                clientName: info.event._def.title, 
                userID: info.event._def.extendedProps.userID,
                userHeight: info.event._def.extendedProps.height,
                userWeight: info.event._def.extendedProps.weight,
             }
        }
        return (
            history.push(location)
        )
    }

    return (
        <div>
            <Navigation />
            <h1 className='calTitle'>{t("calendar.title")}</h1>

            <FullCalendar 
                plugins={[ dayGridPlugin ] }
                initialView='dayGridMonth'
                locale={lang}
                navLinks={true}
                navLinkDayClick={handleNavLinkDayClick}                     
                headerToolbar={
                    {left: 'dayGridMonth,dayGridWeek,dayGridDay,today', 
                    right: 'prevYear,prev,next,nextYear',
                    center: 'title'}
                }
                events={createEvent()}
                dayMaxEvents={true}
                eventClick={handleEventClick}
                
            />
        </div>    
    )
}  

export default Calendar;

/*!
FullCalendar v5.9.0
Docs & License: https://fullcalendar.io/
(c) 2021 Adam Shaw
*/
