import React from 'react'
import './SnoreScreen.css'
import SnoreData from '../../Components/SnoreData/SnoreData'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Navigation from '../../Components/Navigation/Navigation'

function SnoreScreen(props) {
    const { t, i18n } = useTranslation();

    const {snoreData, authInfo, start, end, length, dataID, userID, clientName} = props.location.state;

    const calcAvg = () => {
        const snores = snoreData.items
        var total = 0;
        snores.forEach(snore => {
            const startTime = new Date(snore.start)
            const endTime = new Date(snore.end)
            total += (endTime - startTime) / (60*1000)
            })
        return total/snores.length
    }
        
    const calcMax = () => {
        const snores = snoreData.items
        var maxVal = 0
            snores.forEach(snore => {
                if (snore.volume > maxVal) {
                    maxVal = snore.volume
            }
        })
        return maxVal
    }

    return (
        <div>
            <Navigation />

            <h1>{t("snoreScreen.title")}</h1>
            
            {snoreData.length !== 0 ? (
                <div>
                    <SnoreData data={snoreData} />
                    <h3 className='detailInfo'>{t("snoreScreen.display.started")} {start}</h3>                
                    <h3 className='detailInfo'>{t("snoreScreen.display.ended")} {end}</h3>
                    <h3 className='detailInfo'>{t("snoreScreen.display.duration")} {length} hrs</h3>
                    <h3 className='detailInfo'>{t("snoreScreen.display.tag")} {snoreData.items.tag === 'Yes'? t("snoreScreen.display.potentialIssues"): t("snoreScreen.display.noIssue")}</h3>
                    <h3 className='detailInfo'>{t("snoreScreen.display.avgSnoringLength")} {parseInt(calcAvg(),10)} min </h3>
                    <h3 className='detailInfo'>{t("snoreScreen.display.maxSnoringVolume")} {calcMax()} db</h3>
                </div>
            ) : (
                <div>
                    <p className='noDataMessage'>{t("snoreScreen.display.noData")}</p>
                </div>
            )}
        
            <Link style={{textDecoration: 'none'}} 
                to={{pathname:'/Feedback',
                state:{authInfo:authInfo, dataID: dataID, userID: userID, clientName: clientName}}} >
                <button className='feedbackButton'>
                    <p >{t("snoreScreen.function.feedback")}</p>
                </button>
            </Link>
        </div>
    ) 
}
   
export default SnoreScreen
