/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://hlfedptwxrai3mqfg3gboxg53y.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kxodevkterh6no7j2zzanrbhu4",
    "aws_cognito_identity_pool_id": "us-west-2:e22de311-92fe-4f50-a17c-7fdfca103edd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_2aR748gKa",
    "aws_user_pools_web_client_id": "3ibi9l5e3517kmtogdudv29mpg",
    "oauth": {
        "domain": "joysleep2efffc3f-2efffc3f-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.joybandusa.com/",
        "redirectSignOut": "https://www.joybandusa.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS"
};


export default awsmobile;
